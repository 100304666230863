<template>
  <div class="article" :class="{ active: fullScreen == true }">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <h2 class="curr_time">
          {{ curr_date }}
          <br />{{ curr_weekDay }}
          <span>{{ curr_time }}</span>
        </h2>
        <button class="btn_sub1 refresh_btn" @click="refreshData">
          재조회
        </button>
        <button @click="fullScreen = !fullScreen" class="btn_sub1">
          {{ fullScreen ? '닫기' : '전체화면' }}
        </button>
      </div>
      <swiper
        ref="mySwiperRef"
        class="swiper-container"
        :options="swiperOption"
      >
        <swiper-slide
          class="mes_tbl_wrap swiper-slide"
          v-for="(items, index) in filteredRequest"
          :key="index"
        >
          <div class="auto_width">
            <table class="mes_tbl">
              <colgroup>
                <col v-for="(n, index) in 4" :key="index" />
              </colgroup>
              <thead>
                <tr>
                  <th colspan="8">
                    출고지시 현황<span>조회시간 : {{ str_time }}</span>
                  </th>
                </tr>
                <tr>
                  <th>품목</th>
                  <th>납품처</th>
                  <th>품목(단위)</th>
                  <th>수량(단위)</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="request in items" :key="request.id">
                  <td>
                    {{ findInfoFromId(products, request.product_id).name }}
                  </td>
                  <td>
                    {{
                      findInfoFromId(
                        companys,
                        findInfoFromId(sales, request.sales_id).company_id,
                      ).name
                    }}
                  </td>
                  <td>
                    {{ request.standard }}
                  </td>
                  <td>
                    {{
                      `${$makeComma(request.quantity)}(${
                        findInfoFromId(
                          units,
                          findInfoFromId(products, request.product_id)
                            .stock_unit_id,
                        ).name
                      })`
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </swiper-slide>
      </swiper>
      <div class="slide_btn_wrap" v-show="filteredRequest.length != 0">
        <button type="button" class="button-prev">
          <i class="fa fa-angle-left"></i>
        </button>
        <button
          type="button"
          class="button-play-stop"
          :class="{ active: playStop }"
          @click.prevent="autoPlay()"
        ></button>
        <button type="button" class="button-next">
          <i class="fa fa-angle-right"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import FETCH_MIXIN from '@/mixins/fetch';
import SWIPER_MIXIN from '@/mixins/status_board_swiper';
import {
  yyyy년MM월dd일HH시mm분ss초,
  yyyy년MM월dd일,
  HH시mm분,
  X요일,
} from '@/utils/func';
import { mapGetters } from 'vuex';

export default {
  mixins: [FETCH_MIXIN, SWIPER_MIXIN],
  data() {
    return {
      fullScreen: false,
      date: yyyy년MM월dd일HH시mm분ss초(new Date()),
      timerCount: 60,
      timerMax: 60,
      interval: null,
      timeInterval: null,
      currentTimeInterval: null,
      str_time: null,
      curr_date: yyyy년MM월dd일(new Date()),
      curr_time: HH시mm분(new Date()),
      curr_weekDay: X요일(new Date()),
    };
  },
  computed: {
    ...mapGetters({
      companys: 'getCompany',
      products: 'getProduct',
      units: 'getUnitCodes',
      sales: 'getSalesFromOutputRequest',
      tabIndex: 'getOpenTabIndexFromStatusMonitorPage',
    }),
    filteredRequest() {
      if (this.sales != undefined) {
        let salesClone = this.lodash.clonedeep(this.sales);
        try {
          let filterClone = salesClone
            .map(x => x.product_list)
            .reduce((a, b) => a.concat(b))
            .sort((a, b) => b.id - a.id)
            .filter(x => x.request_out);
          let arr = [];
          if (this.fullScreen) {
            filterClone.forEach((element, index) => {
              if (index % 9 == 0) {
                arr.push(filterClone.slice(index, index + 9));
              }
            });
          } else {
            filterClone.forEach((element, index) => {
              if (index % 5 == 0) {
                arr.push(filterClone.slice(index, index + 5));
              }
            });
          }
          return arr;
        } catch (error) {
          return [];
        }
      } else {
        return '';
      }
    },
  },
  methods: {
    async refreshData() {
      await this.FETCH_NO_SPINNER('FETCH_SALES_TO_OUTPUT_REQUEST', '입출고');
      this.str_time = yyyy년MM월dd일HH시mm분ss초(new Date());
      this.curr_date = yyyy년MM월dd일(new Date());
      this.curr_time = HH시mm분(new Date());
      this.curr_weekDay = X요일(new Date());
    },
  },
  async created() {
    if (this.products.length < 1) {
      await this.FETCH('FETCH_PRODUCT_WITH_COMPANY', '제품');
    }
    if (this.companys.length < 1) {
      await this.FETCH('FETCH_COMPANY', '거래처');
    }
    if (this.units.length < 1) {
      await this.FETCH('FETCH_UNIT', '단위');
    }
    await this.FETCH('FETCH_SALES_TO_OUTPUT_REQUEST', '입출고');
    this.timerCount = this.timerMax;

    this.interval = setInterval(async () => {
      this.timerCount = this.timerMax;

      if (this.tabIndex == 3) {
        await this.FETCH_NO_SPINNER('FETCH_SALES_TO_OUTPUT_REQUEST', '입출고');
      }
      clearInterval(this.timeInterval);
      this.timeInterval = setInterval(() => {
        this.timerCount -= 1;
      }, 1000);
    }, this.timerMax * 10000);

    this.timeInterval = setInterval(() => {
      this.timerCount -= 1;
    }, 1000);

    this.currentTimeInterval = setInterval(() => {
      this.str_time = yyyy년MM월dd일HH시mm분ss초(new Date());
      this.curr_date = yyyy년MM월dd일(new Date());
      this.curr_time = HH시mm분(new Date());
      this.curr_weekDay = X요일(new Date());
    }, 1000);
  },
};
</script>

<style lang="scss" scoped></style>
