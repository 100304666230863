<template>
  <div class="article" :class="{ active: fullScreen == true }">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <h2 class="curr_time">
          {{ curr_date }}
          <br />{{ curr_weekDay }}
          <span>{{ curr_time }}</span>
        </h2>
        <button type="button" class="btn_sub1 refresh_btn" @click="refreshData">
          재조회
        </button>
        <button @click="fullScreen = !fullScreen" class="btn_sub1">
          {{ fullScreen ? '닫기' : '전체화면' }}
        </button>
        <!-- <h5>조회시간 : {{ str_time }}</h5> -->
      </div>
      <div class="mes_tbl_wrap">
        <table class="mes_tbl">
          <colgroup class="work_order_status">
            <col v-for="(n, index) in 6" :key="index" />
          </colgroup>
          <thead>
            <tr>
              <th colspan="6">
                작업지시 현황 <span>조회시간 : {{ str_time }}</span>
              </th>
            </tr>
            <tr class="d_day">
              <th>생산기한</th>
              <th>품목명</th>
              <th>작업수량</th>
              <th>단위</th>
              <th>작업지시일</th>
              <th>납품처</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="lot in lots" :key="lot.id" :class="{ d_day: lot.d_day }">
              <td>
                {{
                  lot.sales_id == undefined || lot.sales_id == null
                    ? lot.deadline_date
                    : findInfoFromId(sales, lot.sales_id) != undefined
                    ? findInfoFromId(sales, lot.sales_id).delivery_date
                    : lot.deadline_date
                }}
              </td>
              <td class="text_left">
                {{
                  lot.sales_id == undefined || lot.sales_id != null
                    ? findInfoFromId(products, lot.product_id).name +
                      `${
                        findInfoFromId(
                          products,
                          lot.product_id,
                        ).standard.trim() != ''
                          ? ' - '
                          : ''
                      }` +
                      findInfoFromId(products, lot.product_id).standard
                    : ''
                }}
              </td>
              <td class="text_right">
                {{ $makeComma(lot.quantity) }}
              </td>
              <td>
                {{
                  findInfoFromId(
                    units,
                    findInfoFromId(products, lot.product_id).stock_unit_id,
                  ).name
                }}
              </td>
              <td>{{ lot.input_date | formatDateNoHours }}</td>
              <td>
                {{
                  lot.company_id != null
                    ? findInfoFromId(companys, lot.company_id).name
                    : '자체생산'
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import fetchMixin from '@/mixins/fetch';
import {
  yyyymmdd,
  yyyy년MM월dd일HH시mm분ss초,
  yyyy년MM월dd일,
  HH시mm분,
  X요일,
} from '@/utils/func';
// import { format년월일요일시분초 } from '@/utils/filters';

export default {
  mixins: [fetchMixin],
  data() {
    return {
      fullScreen: false,
      str_time: null,
      timeCount: 30,
      timerMax: 30,
      interval: null,
      timeInterval: null,
      currentTimeInterval: null,
      start_date: null,
      end_date: null,
      timeNowStr: null,
      timeNowInterval: null,
      date: yyyy년MM월dd일HH시mm분ss초(new Date()),
      curr_date: yyyy년MM월dd일(new Date()),
      curr_time: HH시mm분(new Date()),
      curr_weekDay: X요일(new Date()),
    };
  },
  computed: {
    ...mapGetters({
      products: 'getProduct',
      units: 'getUnitCodes',
      companys: 'getCompany',
      sales: 'getSales',
      lots_src: 'getLot',
      lot_types: 'getLotType',
    }),
    lots() {
      if (this.lots_src.length < 1) {
        return [];
      } else {
        const today = yyyymmdd(new Date());
        let lotSrcTemp = this.lodash
          .clonedeep(this.lots_src)
          .filter(
            x =>
              !this.findInfoFromId(this.products, x.product_id).oem_product_yn,
          );

        lotSrcTemp = lotSrcTemp.map(x => {
          x.deadline_date_temp = x.deadline_date;
          x.d_day = false;
          if (x.sales_id != null) {
            const foundSales = this.lodash.clonedeep(
              this.findInfoFromId(this.sales, x.sales_id),
            );
            if (foundSales != undefined) {
              x.deadline_date_temp = foundSales.delivery_date;
            }
          }
          let minus_one_day = new Date(x.deadline_date_temp);
          minus_one_day = minus_one_day.setDate(minus_one_day.getDate() - 1);
          minus_one_day = yyyymmdd(new Date(minus_one_day));

          if (
            Number(today.replace(/-/gi, '')) >=
            Number(minus_one_day.replace(/-/gi, ''))
          ) {
            x.d_day = true;
          }
          return x;
        });
        return lotSrcTemp
          .sort(
            (a, b) =>
              new Date(a.deadline_date_temp) - new Date(b.deadline_date_temp),
          )
          .filter(x => x.lot_type_id != 3);
      }
    },
  },
  methods: {
    async refreshData() {
      this.str_time = yyyy년MM월dd일HH시mm분ss초(new Date());
      this.curr_date = yyyy년MM월dd일(new Date());
      this.curr_time = HH시mm분(new Date());
      this.curr_weekDay = X요일(new Date());
      this.FETCH_LOT_DAY();
    },
    async FETCH_SALES() {
      this.showSpinner();
      let end_date = new Date();
      end_date = end_date.setDate(end_date.getDate() + 365);

      await this.$store
        .dispatch('FETCH_SALES', {
          start_date: '2001-01-01',
          end_date: yyyymmdd(new Date(end_date)),
        })
        .then(() => {})
        .catch(error => {
          console.log(error);
          this.openOneButtonModal(
            '로드 중 오류',
            '리스트를 불러오는 중 오류발생',
          );
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    FETCH_LOT_DAY() {
      let end_date = new Date();
      end_date = end_date.setDate(end_date.getDate() + 365);
      this.$store
        .dispatch('FETCH_LOT_DAY', {
          start: '2001-01-01',
          end: yyyymmdd(new Date(end_date)),
        })
        .then(response => {
          console.log(response);
        })
        .catch(() => {
          this.openOneButtonModal(
            '조회 중 오류',
            '작업지시 정보를 불러오는 중 오류가 발생했습니다.',
          );
        })
        .finally(() => {});
    },
  },
  destroyed() {
    clearInterval(this.timeInterval);
    clearInterval(this.timeNowInterval);
  },
  async created() {
    this.timeNowStr = new Date();
    this.timeNowInterval = setInterval(() => {
      const newDate = new Date();
      this.str_time = yyyy년MM월dd일HH시mm분ss초(newDate);
      this.curr_date = yyyy년MM월dd일(newDate);
      this.curr_time = HH시mm분(newDate);
      this.curr_weekDay = X요일(newDate);
    }, 1000);
    if (this.lot_types.length < 1) {
      await this.FETCH('FETCH_LOT_TYPE', '작업지시 상태');
    }
    if (this.products.length < 1) {
      await this.FETCH('FETCH_PRODUCT_WITH_COMPANY', '제품');
    }
    if (this.units.length < 1) {
      await this.FETCH('FETCH_UNIT', '단위');
    }
    if (this.companys.length < 1) {
      await this.FETCH('FETCH_COMPANY', '납품처');
    }
    await this.FETCH_LOT_DAY();
    await this.FETCH_SALES();

    this.timerCount = this.timerMax;

    this.interval = setInterval(async () => {
      this.timerCount = this.timerMax;

      if (this.tabIndex == 2) {
        this.FETCH_LOT_DAY();
      }
      clearInterval(this.timeInterval);
      this.timeInterval = setInterval(() => {
        this.timerCount -= 1;
      }, 1000);
    }, this.timerMax * 10000);

    this.timeInterval = setInterval(() => {
      this.timerCount -= 1;
    }, 1000);

    this.str_time = yyyy년MM월dd일HH시mm분ss초(new Date());
    this.curr_date = yyyy년MM월dd일(new Date());
    this.curr_time = HH시mm분(new Date());
    this.curr_weekDay = X요일(new Date());
  },
};
</script>

<style lang="scss" scoped></style>
